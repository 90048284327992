var _0x15102f = _0x54e5;
(function (_0x49a896, _0x2a99e7) {
    var _0x2e5d03 = _0x54e5, _0x168329 = _0x49a896();
    while (!![]) {
        try {
            var _0x5e3265 = parseInt(_0x2e5d03(0x1fe)) / (-0x26ea + 0x2 * 0x955 + 0x1441) * (parseInt(_0x2e5d03(0x1fb)) / (0x5 * -0xe3 + -0x29 * -0x8f + -0x116 * 0x11)) + -parseInt(_0x2e5d03(0x1fc)) / (-0x26ab * 0x1 + 0x1 * -0x22b4 + 0x4962) * (parseInt(_0x2e5d03(0x1ea)) / (0x9c3 + 0x5ad * 0x2 + -0x1519)) + parseInt(_0x2e5d03(0x1f0)) / (-0x277 * -0x7 + 0x1324 + -0x308 * 0xc) + -parseInt(_0x2e5d03(0x1ec)) / (-0x1170 + 0x1f50 + -0xdda) * (parseInt(_0x2e5d03(0x1f3)) / (0x206e + 0x223e + -0x42a5)) + parseInt(_0x2e5d03(0x1f5)) / (0x971 + 0x1014 + 0x105 * -0x19) * (-parseInt(_0x2e5d03(0x1f4)) / (0x251 * -0xd + 0x2482 + 0x2 * -0x32e)) + parseInt(_0x2e5d03(0x1e9)) / (0x1d06 + -0x1d * 0xef + 0x1 * -0x1e9) + -parseInt(_0x2e5d03(0x1f9)) / (0x29 * -0x6a + -0x2113 + -0x728 * -0x7);
            if (_0x5e3265 === _0x2a99e7)
                break;
            else
                _0x168329['push'](_0x168329['shift']());
        } catch (_0x5aec2a) {
            _0x168329['push'](_0x168329['shift']());
        }
    }
}(_0x4f42, 0x132eb3 + -0x132509 + -0x163a4 * -0xa));
import { http } from '@/utils/axios';
import _0x4ff508 from '@/utils/lang';
var api = {
    'header': (_0x15102f(0x1f2) + _0x15102f(0x1ff) + _0x15102f(0x1f7) + _0x15102f(0x1f8))[_0x15102f(0x1f6)](_0x4ff508),
    'homePage': (_0x15102f(0x1f2) + _0x15102f(0x1ff) + _0x15102f(0x1eb) + _0x15102f(0x1f1))[_0x15102f(0x1f6)](_0x4ff508),
    'ipLang': (_0x15102f(0x1f2) + _0x15102f(0x1ff) + _0x15102f(0x1ef) + 'ng')[_0x15102f(0x1f6)](_0x4ff508)
};
export var header = function header() {
    var _0x1392fe = _0x15102f, _0x2338aa = {
            'JGqbe': function (_0x204e7c, _0x5cff68) {
                return _0x204e7c(_0x5cff68);
            }
        };
    return _0x2338aa[_0x1392fe(0x1e7)](http, api[_0x1392fe(0x1e8)]);
};
export var homePage = function homePage() {
    var _0x63c6cc = _0x15102f, _0x47acb4 = {
            'TrlGa': function (_0x24d2f0, _0xa8f8c0) {
                return _0x24d2f0(_0xa8f8c0);
            }
        };
    return _0x47acb4[_0x63c6cc(0x1ed)](http, api[_0x63c6cc(0x1fd)]);
};
function _0x54e5(_0x310172, _0x294cf1) {
    var _0x1229b8 = _0x4f42();
    return _0x54e5 = function (_0x4f0850, _0x151b93) {
        _0x4f0850 = _0x4f0850 - (0x29f * 0x7 + 0x38c + 0x3 * -0x6aa);
        var _0x299944 = _0x1229b8[_0x4f0850];
        if (_0x54e5['RnesFp'] === undefined) {
            var _0xa00fbd = function (_0x552546) {
                var _0x414d7a = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789+/=';
                var _0x1c0618 = '', _0x306574 = '';
                for (var _0x388a4c = -0xcbd + -0x3 * 0x2f1 + -0xac8 * -0x2, _0x1afd2e, _0x362d98, _0x21ee90 = -0x1bdc + 0x381 + 0x1d * 0xd7; _0x362d98 = _0x552546['charAt'](_0x21ee90++); ~_0x362d98 && (_0x1afd2e = _0x388a4c % (0x9cb + -0xebe + -0x4f7 * -0x1) ? _0x1afd2e * (-0x17f5 + -0x96b * -0x2 + -0x113 * -0x5) + _0x362d98 : _0x362d98, _0x388a4c++ % (0x1cb9 + -0xc53 * 0x1 + 0x2bb * -0x6)) ? _0x1c0618 += String['fromCharCode'](-0x1e56 + 0x1357 + 0x5 * 0x266 & _0x1afd2e >> (-(0x5 * -0x2a0 + 0x1c20 + -0xefe) * _0x388a4c & 0x1a70 + 0x20b * -0x5 + -0x1033)) : 0x1 * -0x1e6b + 0xb99 * 0x2 + 0x739) {
                    _0x362d98 = _0x414d7a['indexOf'](_0x362d98);
                }
                for (var _0x114fa5 = 0x24fc + 0xc * -0x260 + 0x2d4 * -0x3, _0x54faf2 = _0x1c0618['length']; _0x114fa5 < _0x54faf2; _0x114fa5++) {
                    _0x306574 += '%' + ('00' + _0x1c0618['charCodeAt'](_0x114fa5)['toString'](-0x1 * 0x4db + 0x16 * -0x136 + 0x1f8f))['slice'](-(-0xa8b + -0x2 * 0x185 + 0xd97));
                }
                return decodeURIComponent(_0x306574);
            };
            _0x54e5['YKHerX'] = _0xa00fbd, _0x310172 = arguments, _0x54e5['RnesFp'] = !![];
        }
        var _0x2a01c9 = _0x1229b8[0x9 * 0x109 + 0x503 * -0x1 + -0x227 * 0x2], _0x435b9a = _0x4f0850 + _0x2a01c9, _0x11b13c = _0x310172[_0x435b9a];
        return !_0x11b13c ? (_0x299944 = _0x54e5['YKHerX'](_0x299944), _0x310172[_0x435b9a] = _0x299944) : _0x299944 = _0x11b13c, _0x299944;
    }, _0x54e5(_0x310172, _0x294cf1);
}
function _0x4f42() {
    var _0x4898a2 = [
        'mJmZmta0oxLTyM5SsG',
        'mte3nJG4nu9KsNrwCa',
        'odbPu3jOzMu',
        'y29Uy2f0',
        'Ag9Tzs9OzwfKzq',
        'CI9Syw5N',
        'ntG5mJe1mgXpDxbyAa',
        'Axbmyw5N',
        'mJiXndi5ohDcsfvtwa',
        'nMzbD2fJBa',
        'Ag9TzvbHz2u',
        'mxHYrhn4rW',
        'sw50zxjMywnLlW',
        'sKDXyMu',
        'AgvHzgvY',
        'mtCWmdiZotb6zvLerNa',
        'odK1mZy4D2z3s2Pv',
        'Ag9Tzs9OB21LxW',
        'mJrZALvgzfG',
        'vhjSr2e',
        'u1bdD3K',
        'Ag9Tzs9PCc9Syq',
        'ody0mJy5mePHquvVra',
        'CgfNzs9Syw5N',
        'l2fWAs9MCM9UDa'
    ];
    _0x4f42 = function () {
        return _0x4898a2;
    };
    return _0x4f42();
}
export var ipLang = function ipLang() {
    var _0x2ab06a = _0x15102f, _0x4c0015 = {
            'SPCwy': function (_0x331675, _0x382701) {
                return _0x331675(_0x382701);
            }
        };
    return _0x4c0015[_0x2ab06a(0x1ee)](http, api[_0x2ab06a(0x1fa)]);
};