import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Tabs, Badge, Dropdown, Menu } from "antd";
import { header } from "@/api/home";
import "@/style/common/header.less";
import { languages } from "@/api/common";
import lang from "@/utils/lang";
import { CaretRightOutlined, CaretDownOutlined, DownOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { setLang } from "@/redux/actionCreators";
import Cookie from 'js-cookie';
function deep(arr, level) {
  let newArr = arr.map((item) => {
    if (item.children && item.children.length > 0) {
      var children = deep(item.children, level + 1);
    }
    return {
      ...item,
      level,
      fold: true,
      children: children ? children : [],
    };
  });
  return newArr;
}
class Header extends Component {
  static defaultProps = {
    showHead: true,
  };
  constructor() {
    super();
    this.state = {
      navItem: [
        {
          nav_name: "Solutions",
          nav_linkurl: "/SolutionsDetail",
          nav_linktype: 1,
          children: [
            {
              nav_name: "Home Fire Safety",
              nav_linkurl: "/SolutionsDetail",
              nav_linktype: 1,
            },
            {
              nav_name: "Commercial Fire Safety",
              nav_linkurl: "/SolutionsDetail",
              nav_linktype: 1,
            },
            {
              nav_name: "Industrial Fire Safety",
              nav_linkurl: "/SolutionsDetail",
              nav_linktype: 1,
            },
            {
              nav_name: "Fire-fighting Robots",
              nav_linkurl: "/SolutionsDetail",
              nav_linktype: 1,
            },
          ],
        },
        {
          nav_name: "Products",
          nav_linkurl: "/Product",
          nav_linktype: 1,
          children: [
            {
              nav_name: "Smart Home Alarms",
              nav_linkurl: "/ProductDetail",
              nav_linktype: 1,
              children: [
                {
                  nav_name: "Smoke Alarm",
                  nav_linkurl: "/ProductDetail",
                  nav_linktype: 1,
                },
                {
                  nav_name: "Gas Alarm",
                  nav_linkurl: "/ProductDetail",
                  nav_linktype: 1,
                },
                {
                  nav_name: "Carbon Monoxide Alar",
                  nav_linkurl: "/ProductDetail",
                  nav_linktype: 1,
                },
                {
                  nav_name: "Heat Alarm",
                  nav_linkurl: "/ProductDetail",
                  nav_linktype: 1,
                },
                {
                  nav_name: "AI-Fire Products",
                  nav_linkurl: "/ProductDetail",
                  nav_linktype: 1,
                },
                {
                  nav_name: "Air Quality Monitor",
                  nav_linkurl: "/ProductDetail",
                  nav_linktype: 1,
                },
              ],
            },
            {
              nav_name: "Smart Home Alarms",
              nav_linkurl: "/ProductDetail",
              nav_linktype: 1,
              children: [
                {
                  nav_name: "Smoke Alarm",
                  nav_linkurl: "/ProductDetail",
                  nav_linktype: 1,
                },
              ],
            },
            {
              nav_name: "Smart Home Alarms",
              nav_linkurl: "/ProductDetail",
              nav_linktype: 1,
              children: [
                {
                  nav_name: "Smoke Alarm",
                  nav_linkurl: "/ProductDetail",
                  nav_linktype: 1,
                },
                {
                  nav_name: "Gas Alarm",
                  nav_linkurl: "/ProductDetail",
                  nav_linktype: 1,
                },
              ],
            },
            {
              nav_name: "Smart Home Alarms",
              nav_linkurl: "/ProductDetail",
              nav_linktype: 1,
              children: [
                {
                  nav_name: "Smoke Alarm",
                  nav_linkurl: "/ProductDetail",
                  nav_linktype: 1,
                },
                {
                  nav_name: "Gas Alarm",
                  nav_linkurl: "/ProductDetail",
                  nav_linktype: 1,
                },
              ],
            },
            {
              nav_name: "Smart Home Alarms",
              nav_linkurl: "/ProductDetail",
              nav_linktype: 1,
              children: [
                {
                  nav_name: "Smoke Alarm",
                  nav_linkurl: "/ProductDetail",
                  nav_linktype: 1,
                },
                {
                  nav_name: "Gas Alarm",
                  nav_linkurl: "/ProductDetail",
                  nav_linktype: 1,
                },
              ],
            },
          ],
        },
        {
          nav_name: "Support",
          nav_linkurl: "",
          nav_linktype: 3,
          children: [
            {
              nav_name: "Service Policy",
              nav_linkurl: "/ServicesPolicy",
              nav_linktype: 1,
            },
            {
              nav_name: "Download",
              nav_linkurl: "/Download",
              nav_linktype: 1,
            },
            {
              nav_name: "FAQ",
              nav_linkurl: "/FAQ",
              nav_linktype: 1,
            },
            {
              nav_name: "Videos",
              nav_linkurl: "/Videos",
              nav_linktype: 1,
            },
          ],
        },
        {
          nav_name: "About Us",
          nav_linkurl: "/AboutUs",
          nav_linktype: 1,
          children: [
            {
              nav_name: "About Wisualarm",
              nav_linkurl: "/AboutUs",
              nav_linktype: 1,
            },
            {
              nav_name: "Partner",
              nav_linkurl: "/Partner",
              nav_linktype: 1,
            },
            {
              nav_name: "Blog",
              nav_linkurl: "/Blog",
              nav_linktype: 1,
            },
            {
              nav_name: "ContactUs",
              nav_linkurl: "/ContactUs",
              nav_linktype: 1,
            },
          ],
        },
        {
          nav_name: "Online Shop",
          nav_linkurl: "https://buy.wisualarm.com/",
          nav_linktype: 2,
        },
      ],
      logoFlag: false,
      navIndex: -1,
      navClass: true,
      showNav: false,
      whiteBgShow: false,
      // solution数据
      solutionDetail: {},
      // production数据
      productionDeatil: {
        nav_label: [],
      },
      //语言数组
      languageArr: [],
      selectOpen: false,
      isAgree: false,
      //OnlineShop取消遮罩层和下划线
      isOnlineShop: true,
    };
  }
  setCookie() {
    let cookie = document.cookie.split(";");
    const flag = cookie.some((item) => {
      const arr = item.split("=");
      return arr.some((item) => {
        return item.trim() === "indexAgreeCookie";
      });
    });
    this.setState({
      isAgree: flag,
    });
  }
  async UNSAFE_componentWillMount() {
    this.setCookie();
    let { data } = await header();
    let { data: languageData } = await languages();
    let arr = deep(data, 1);
    this.setState({
      navItem: arr,
      languageArr: languageData.map((item, index) => {
        return {
          value: item.language_code,
          label: item.language_code.toUpperCase(),
          ...item,
        };
      }),
      solutionDetail: data[1].children[0].children[0] || {},
      productionDeatil: data[0].children[0].children[0].children[0] || { nav_label: [] },
    });
  }
  async componentDidMount() {
    window.addEventListener("scroll", () => this.getTopHandle());
    this.props.history.listen((location) => {
      this.setState({
        navIndex: -1,
      });
    });
  }
  // 获取头部滚动的距离
  getTopHandle() {
    const scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop;
    this.setState({
      whiteBgShow: scrollTop > 0 ? true : false,
    });
  }
  // 去解决方案详情页
  goSolutionDetail(name, id, menu_id) {
    if (name === "Solutions") {
      this.props.history.push(`/SolutionsDetail?id=${id}&menu_id=${menu_id}`);
    } else {
      this.props.history.push(`/ProductDetail?id=${id}`);
    }
  }
  // 产品跳转
  jumpProductDetail(itemChild) {
    if (itemChild.type === 'class') {
      this.props.history.push(`/Product?menu_id=${itemChild.nav_id}`);
    } else {
      this.props.history.push(`/ProductDetail?id=${itemChild.nav_id}`);
    }
  }
  jumpSolutionDetail(itemChild) {
    this.props.history.push(
      `/SolutionsDetail?id=${itemChild.nav_id}&menu_id=${itemChild.menu_id}`
    );
  }
  productMove(item) {
    this.setState({
      productionDeatil: item,
    });
  }
  solutionMove(item) {
    this.setState({
      solutionDetail: item,
    });
  }
  /**移动端一级导航跳转 */
  handleMoblieNav(e, item, index) {
    let { navItem } = this.state;
    let chilItem = navItem[index];
    const { fold } = chilItem;
    chilItem.fold = !fold;
    /**移动端Products、Abuot us一级导航可以跳转
     * Solutions,Support模块不跳转
     */
    if (["2", "4"].includes(item.nav_id)) {
      this.props.history.push(item.nav_link_name);
      // this.setState({
      //   showNav: false
      // })
    }
    this.setState({
      navItem: [...navItem],
    });
    e.stopPropagation();
  }
  /**移动端Support、AboutUs模块二级导航跳转*/
  handleChildNav(e, child) {
    // this.setState({
    //   showNav: false
    // })
    this.props.history.push(child.nav_link_name);
    e.stopPropagation();
    this.setState({ showNav: !this.state.showNav })
  }
  /**移动端solution和product二级导航处理 */
  handleSpecialNav(e, index, chilIndex, modelName) {
    const { navItem } = this.state;
    const parent = navItem[index];
    const item = parent.children[chilIndex];
    let { fold } = item;
    item.fold = !fold;
    if (modelName === "Products") {
      this.props.history.push(`/Product?menu_id=${item.nav_id}`);
    } else {
      this.props.history.push(`/Solutions?id=${item.nav_id}`);
    }
    this.setState({
      navItem: [...navItem],
    });
    e.stopPropagation();
  }
  /**移动端三级导航 */
  handleSonNav(e, name, son) {
    e.stopPropagation();
    this.goSolutionDetail(name, son.nav_id, son.menu_id);
    this.setState({ showNav: !this.state.showNav })
  }
  /**PC端solution二级展开与收缩 */
  handleSolutionFold(index, solutionIndex) {
    const { navItem } = this.state;
    const item = navItem[index].children[solutionIndex];
    const { fold } = item;
    item.fold = !fold;
    this.setState({
      navItem: [...navItem],
    });
    this.props.history.push(`/Solutions?id=${item.nav_id}`);
  }
  /**遮罩层点击 */
  handleClickMask() {
    document.body.style.overflow = 'auto' // 开启页面滑动
    this.setState({
      showNav: false,
    });
  }
  handleChange(value, option) {
    if (value === 'cn') {
      if (option.language_url) {
        window.open(option.language_url);
      } else {
        this.props.history.push(value);
      }
    } else {
      let newLink = window.open("about:blank");
      newLink.location = `/${value}`;
    }
    Cookie.set('ipLang', value)
  }
  handeleMouseEnter(index, item) {
    this.setState({
      navClass: item.children.some(i => i.children.length >= 10),
      activeKeyNavId: item.children.length > 0 ? item.children[0].nav_id : ''
    });
    this.setState({ navIndex: index, logoFlag: true }, () => {
      const { navIndex } = this.state;
      if (navIndex === 4) {
        this.setState({ logoFlag: false });
      }
      document.body.style.overflow = 'hidden' // 禁止页面滑动
    });
  }
  handeleMouseLeave() {
    document.body.style.overflow = 'auto' // 开启页面滑动
    this.setState({ logoFlag: false });
    this.setState({ navIndex: -1 });
  }
  goServicesPolicy() {
    this.props.history.push("/ServicesPolicy");
  }
  handleAgree() {
    document.cookie = "indexAgreeCookie=false";
    this.setState({
      isAgree: true,
    });
  }
  componentWillUnmount() {
    this.setState = () => false;
  }
  render() {
    const { TabPane } = Tabs
    const {
      navItem,
      logoFlag,
      navIndex,
      showNav,
      whiteBgShow,
      solutionDetail,
      productionDeatil,
      languageArr,
      isAgree,
      navClass,
      activeKeyNavId,
      listNavId
    } = this.state;
    const { language, showHead, shopCart } = this.props;
    const langItem = languageArr.filter(item => { return item.value == lang.slice(1) }) || lang.slice(1)
    const langLabel = langItem.length > 0 ? langItem[0].label : lang.slice(1);
    const langs = lang.slice(1);
    const menu = (
      <Menu>
        {
          languageArr.map((item, index) => {
            return (
              <Menu.Item key={index} onClick={() => this.handleChange(item.value, item)}>{item.label}</Menu.Item>
            );
          })
        }
      </Menu>
    );
    /**默认头部背景为白色的页面 */
    const pathFlag = [
      "/404",
      "/ProductDetail",
      "/InquiryList",
      "/Blog",
      "/blogDetail",
      "/ServicesPolicy",
      "/PrivacyPolicy",
      "/TermOfUs",
      "/EOLProductList"
    ].includes(this.props.location.pathname);
    let goodsCount = 0
    if (shopCart && shopCart.length) {
      goodsCount = shopCart.map(i => i.goods_list).flat().reduce((pre, cur) => {
        return pre + Number(cur.goods_count)
      }, 0)
    }
    const shoppingCart = this.props.language.lang_shopping_cart_status
    return (
      <div>
        {showHead && (
          <div
            className={`header-wrap ${logoFlag || pathFlag || whiteBgShow ? "white-bg" : ""
              } ${pathFlag ? "head-bottom-line" : ""}`} id="header"
          >
            {/* pc端 */}
            <div className="pc-header ">
              <div className="pc-header-logo">
                <Link to="/">
                  {logoFlag || pathFlag || whiteBgShow ? (
                    <img src="/img/logo-black.png" alt="logo" />
                  ) : (
                    <img src="/img/logo-white.png" alt="logo" />
                  )}
                </Link>
              </div>
              <ul className="pc-header-nav">
                {navItem?.length > 0 &&
                  navItem.map((item, index) => {
                    return (
                      <li
                        key={index}
                        className="nav-item"
                        onMouseEnter={() => this.handeleMouseEnter(index, item)}
                        onMouseLeave={() => this.handeleMouseLeave()}
                      >
                        {["2", "4"].includes(item.nav_id) && (
                          <Link
                            to={`${item.nav_link_name}`}
                            className="item-link"
                          >
                            {item.nav_name}
                          </Link>
                        )}
                        {["5"].includes(item.nav_id) && (
                          <a
                            href={item.nav_linkurl}
                            target="_blank"
                            className="item-link"
                            rel="nofollow noopener noreferrer"
                          >
                            {item.nav_name}
                            <i className="iconfont icon-qianwangtiaozhuan"></i>
                          </a>
                        )}
                        {["1", "3"].includes(item.nav_id) && (
                          <span className="item-link">{item.nav_name}</span>
                        )}
                        {["3", "4"].includes(item.nav_id) &&
                          item.children?.length && (
                            <div className={`more-select ${navIndex === index ? "select-show" : "" }`}>
                              <ul className="select-nav">
                                {item.children.map((item, index) => {
                                  return (
                                    <li key={index}>
                                      <Link
                                        to={item.nav_link_name}
                                        className="select-nav-link"
                                      >
                                        {item.nav_name}
                                      </Link>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          )}
                        {/* 产品下拉 */}
                        {["2"].includes(item.nav_id) &&
                          item.children?.length && (
                            <div className={`product-select ${navIndex === index ? "select-show" : ""}`}>
                              <div className="prouct-select-content container">
                                <div className="left-nav">
                                  <Tabs tabPosition="left" activeKey={activeKeyNavId}>
                                    {item.children.map((childItem) => {
                                      return (
                                        <TabPane
                                          className="Tabs"
                                          key={childItem.nav_id}
                                          tab={
                                            <div
                                              onMouseEnter={() => {
                                                this.setState({
                                                  activeKeyNavId: childItem.nav_id,
                                                  listNavId: childItem.children.length > 0 ? childItem.children[0].nav_id : '',
                                                  productionDeatil: childItem.children.length > 0 ?
                                                    childItem.children[0].length > 0 ? childItem.children[0].children[0] : '' : ''
                                                })
                                              }}
                                            >
                                              {childItem.nav_name}
                                            </div>
                                          }
                                        >
                                          <Tabs tabPosition="left" activeKey={listNavId} className="navTabs">
                                            {childItem.children?.length > 0 &&
                                              childItem.children?.map((navItem) => {
                                                return (
                                                  <TabPane
                                                    key={navItem.nav_id}
                                                    tab={
                                                      <div
                                                        onMouseEnter={() => {
                                                          this.setState({
                                                            listNavId: navItem.nav_id,
                                                            productionDeatil: navItem.children.length > 0 ? navItem.children[0] : ''
                                                          })
                                                        }}
                                                      >
                                                        {navItem.nav_name}
                                                        <i className="iconfont icon-youjiantou" style={{ marginLeft: '8px' }}></i>
                                                      </div>
                                                    }
                                                  >
                                                    <div className="list_nav_name">
                                                      {navItem.children.map((listItem) => {
                                                        return (
                                                          <div
                                                            key={listItem.nav_id}
                                                            className="list_nav_tite"
                                                            onMouseEnter={() => {
                                                              this.setState({ productionDeatil: listItem })
                                                            }}
                                                            onClick={this.jumpProductDetail.bind(this, listItem)}
                                                          > {listItem.nav_name} </div>
                                                        )
                                                      })}
                                                    </div>
                                                  </TabPane>
                                                )
                                              })}
                                          </Tabs>
                                        </TabPane>
                                      )
                                    })
                                    }
                                  </Tabs>
                                </div>
                                {productionDeatil.type === 'list' ?
                                  <div
                                    className="right-detail"
                                    onClick={() =>
                                      this.goSolutionDetail(
                                        item.nav_name,
                                        productionDeatil.nav_id
                                      )
                                    }
                                  >
                                    <div className={`img-box ${item.nav_name === "Solutions" ? "solution-img-box" : ""}`} >
                                      {productionDeatil.nav_image ? (
                                        <img
                                          src={productionDeatil.nav_image}
                                        ></img>
                                      ) : null}
                                    </div>
                                    <div className="detail">
                                      <div className="detail-title">
                                        {productionDeatil.nav_name}
                                      </div>
                                      <div className="sub-title">
                                        {productionDeatil.nav_description}
                                      </div>
                                      {item.nav_id === "2" && (
                                        <div className="label-box">
                                          {productionDeatil?.nav_label?.length >
                                            0 &&
                                            productionDeatil.nav_label.map(
                                              (item, index) => {
                                                return (
                                                  <span key={index}>
                                                    {item.name}
                                                  </span>
                                                );
                                              }
                                            )}
                                        </div>
                                      )}
                                    </div>
                                  </div> : ''
                                }
                              </div>
                            </div>
                          )}
                        {/* 解决方案 下拉 */}
                        {["1"].includes(item.nav_id) &&
                          item.children?.length && (
                            <div
                              className={`solution-select ${navIndex === index ? "select-show" : ""
                                } ${item.nav_id === "1" ? "solution-select-height" : ""
                                }`}
                            >
                              <div className="prouct-select-content container">
                                <div className="left-nav">
                                  <div className={`${navClass ? "nav-box" : 'nav-boxs'}`}>
                                    {item.children.map(
                                      (item, solutionIndex) => {
                                        return (
                                          <div
                                            className="nav-list"
                                            key={solutionIndex}
                                            style={{ position: `${item.children.length > 10 ? 'absolute' : ''}` }}
                                          >
                                            <div className="list-title">
                                              <div
                                                onClick={this.handleSolutionFold.bind(
                                                  this,
                                                  index,
                                                  solutionIndex
                                                )}
                                              >
                                                {item.nav_name}
                                                <i className="iconfont icon-youjiantou" style={{ marginLeft: '8px' }}></i>
                                              </div>
                                            </div>
                                            {item.children?.length > 0
                                              ? item.children.map(
                                                (child, childIndex) => {
                                                  return (
                                                    <p
                                                      className="list-detail ellipsis"
                                                      key={childIndex}
                                                      onMouseMove={this.solutionMove.bind(
                                                        this,
                                                        child
                                                      )}
                                                      onClick={this.jumpSolutionDetail.bind(
                                                        this,
                                                        child
                                                      )}
                                                    >
                                                      {child.nav_name}
                                                    </p>
                                                  );
                                                }
                                              )
                                              : null}
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                                <div
                                  className="right-detail"
                                  onClick={() =>
                                    this.goSolutionDetail(
                                      item.nav_name,
                                      solutionDetail.nav_id,
                                      solutionDetail.menu_id
                                    )
                                  }
                                >
                                  <div
                                    className={`img-box ${item.nav_id === "1"
                                      ? "solution-img-box"
                                      : ""
                                      }`}
                                  >
                                    {solutionDetail.image ? (
                                      <img src={solutionDetail.image} alt="" />
                                    ) : null}
                                  </div>
                                  <div className="detail">
                                    <div className="detail-title">
                                      {solutionDetail.nav_name}
                                    </div>
                                    <div className="sub-title">
                                      {solutionDetail.nav_description}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                      </li>
                    );
                  })}
              </ul>
              <div className="pc-header-right">
                <div className="lang-box">
                  <i className="iconfont icon-a-yuyanwangzhi"></i>
                  <Dropdown
                    overlay={menu}
                    placement="bottomRight"
                    overlayStyle={{ width: '80px', textAlign: 'center !important' }}
                    arrow={{
                      pointAtCenter: true,
                    }}
                  >
                    <span>{langLabel} <DownOutlined /></span>
                  </Dropdown>
                </div>
                {shoppingCart !== '0' ? <Badge count={goodsCount > 99 ? 99 : goodsCount} color="#ff0000" className="custom-badge-cls">
                  <Link to="/InquiryList" className="shop-store">
                    <i className="iconfont icon-gouwuche"></i>
                    <span>{language.lang_inquiry_list}</span>
                  </Link>
                </Badge> : ''}
              </div>
            </div>
            {/* 移动端 */}
            <div className="mobile-header container">
              <div className="mobile-header-logo">
                <Link to="/">
                  <img src="/img/logo-black.png" alt="logo" />
                </Link>
              </div>
              <div className="mobile-header-section">
                <div className="lang-box">
                  <Dropdown
                    overlay={menu}
                    placement="bottomRight"
                    overlayStyle={{ width: '80px', textAlign: 'center !important' }}
                    arrow={{
                      pointAtCenter: true,
                    }}
                  >
                    <span>{langLabel} <DownOutlined /></span>
                  </Dropdown>
                </div>
                <div
                  className="nav-select"
                  onClick={() => {
                    this.setState({ showNav: !this.state.showNav })
                    document.body.style.overflow = !showNav ? 'hidden' : 'auto'
                  }
                  }
                >
                  <div className={`nav-icon ${showNav ? "show-nav" : ""}`}>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </div>
            </div>
            {/* 移动端nav */}
            {showNav && (
              <div className="nav-coloapse">
                <ul className="mobile-nav">
                  {navItem?.length > 0 &&
                    navItem.map((item, index) => {
                      return (
                        <li key={index}>
                          {["0", "1"].includes(item.nav_linktype) && (
                            <div className="item-link">
                              <p
                                className="first-level"
                                onClick={(e) =>
                                  this.handleMoblieNav(e, item, index)
                                }
                              >
                                <span>
                                  {item.fold ? (
                                    <CaretRightOutlined />
                                  ) : (
                                    <CaretDownOutlined />
                                  )}
                                </span>
                                {item.nav_name}
                              </p>
                              {["1", "2"].includes(item.nav_id) &&
                                !item.fold &&
                                item.children &&
                                item.children.map((chilItem, chilIndex) => {
                                  return (
                                    <div key={chilIndex} className="child-box">
                                      <p
                                        className="second-level"
                                        onClick={(e) =>
                                          this.handleSpecialNav(
                                            e,
                                            index,
                                            chilIndex,
                                            item.nav_name
                                          )
                                        }
                                      >
                                        {chilItem?.children?.length > 0 ? (
                                          <span>
                                            {chilItem.fold ? (
                                              <CaretRightOutlined />
                                            ) : (
                                              <CaretDownOutlined />
                                            )}
                                          </span>
                                        ) : null}
                                        {chilItem.nav_name}
                                      </p>
                                      {!chilItem.fold &&
                                        chilItem.children &&
                                        chilItem.children.map(
                                          (sonItem, sonIndex) => {
                                            return (
                                              <div
                                                className="son-box"
                                                onClick={(e) =>
                                                  this.handleSonNav(
                                                    e,
                                                    item.nav_name,
                                                    sonItem
                                                  )
                                                }
                                                key={sonIndex}
                                              >
                                                {sonItem.nav_name}
                                              </div>
                                            );
                                          }
                                        )}
                                    </div>
                                  );
                                })}
                              {["3", "4"].includes(item.nav_id) &&
                                !item.fold &&
                                item.children &&
                                item.children.map((chilItem, chilIndex) => {
                                  return (
                                    <div
                                      key={chilIndex}
                                      onClick={(e) =>
                                        this.handleChildNav(e, chilItem)
                                      }
                                      className="child-box"
                                    >
                                      {chilItem.nav_name}
                                    </div>
                                  );
                                })}
                            </div>
                          )}
                          {["2"].includes(item.nav_linktype) && (
                            <a
                              href={item.nav_linkurl}
                              target="_blank"
                              className="item-link"
                              rel="nofollow noopener noreferrer"
                            >
                              {item.nav_name}
                            </a>
                          )}
                        </li>
                      );
                    })}
                </ul>
              </div>
            )}

            {/* 下拉菜单遮罩层 */}
            { }
            {(logoFlag || showNav) && (
              <div
                className={`${navIndex === 0 || navIndex === 1 ? 'mask-box' : 'mask-boxs'}`}
                onClick={this.handleClickMask.bind(this)}
              ></div>
            )}
          </div>
        )}
        {!isAgree && (
          <div className="cookie-tips container">
            <div>
              {language.lang_cookie_text1}{language.lang_cookies_two}{language.lang_cookie_text2}
              {/* <span className="agree-text" onClick={this.handleAgree.bind(this)}>
                {language.lang_cookies_two}
              </span> */}
              <span
                className="cookie-statement"
                onClick={this.goServicesPolicy.bind(this)}
              >
                {language.lang_cookies_four}
              </span>
              .
            </div>
            <div className="agree-text" onClick={this.handleAgree.bind(this)}>{language.lang_cookies_two}</div>
          </div>
        )}
      </div>
    );
  }
}
const stateToProps = (state) => {
  return {
    language: state.language,
    lang: state.lang,
    shopCart: state.shopCart,
  };
};
const dispatchToProps = (dispatch) => {
  return {
    setLang(data) {
      dispatch(setLang(data));
    },
  };
};
export default connect(stateToProps, dispatchToProps)(withRouter(Header));
