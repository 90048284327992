import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import '@/style/common/footer.less'
import { footer as requestFooter, subscribeEmail, follow } from '@/api/common'
import { Input, Form, Button, message, Menu } from 'antd'
import { connect } from 'react-redux'
const SubMenu = Menu.SubMenu;
class Footer extends Component {
  constructor() {
    super()
    this.state = {
      footerNav: [],
      form: {
        email: '',
      },
      // 底部备案数据
      bottomData: {},
      // 底部媒体数据
      follows: [],
      openKeys: []
    }
  }
  formRef = React.createRef()
  async onFinish(value) {
    this.formRef.current.resetFields()
    const formData = new FormData()
    formData.append('email', value.email)
    let res = await subscribeEmail(formData)
    if (res.status == '00') {
      message.success(res.message)
    } else {
      message.error(res.message)
    }
  }
  jumpLink(name, itemChild) {
    switch (name) {
      case 'Solutions':
        this.props.history.push(`/Solutions?id=${itemChild.nav_id}`)
        break;
      case 'Products':
        this.props.history.push(`/Product?menu_id=${itemChild.nav_id}`)
        break;
    }
  }
  handleLink(item) {
    window.open(item.follow_linkurl)
  }
  handlePrivacy() {
    this.props.history.push('/ServicesPolicy')
  }
  async UNSAFE_componentWillMount() {
    let { data } = await requestFooter()
    let { data: data2 } = await follow()
    this.setState({
      bottomData: data,
      footerNav: data.nav_info,
      follows: data2
    })
  }
  // 递归实现菜单渲染
  renderMenu = (data, flag) => {
    return data.map((item) => {
      if (item.children && item.children.length > 0) {
        return (
          <SubMenu key={item.nav_name} title={item.nav_name} className={flag ? 'active-menu-item' : ''}>
            {this.renderMenu(item.children, false)}
          </SubMenu>
        )
      }
      return (
        <Menu.Item onClick={this.onMenuClick.bind(this, item)} key={item.nav_name} title={item.nav_name} className={flag ? 'first-item' : ''}>
          {item.nav_name}
        </Menu.Item>
      )
    })
  }
  onOpenChange(e) {
    const { openKeys } = this.state
    const currentOpenKey = openKeys ? [e.find((key) => openKeys.indexOf(key) === -1)] : e
    this.setState({ openKeys: currentOpenKey })
  }
  onMenuClick(e) {
    const { openKeys } = this.state
    switch (openKeys.join('')) {
      case 'Solutions':
        this.props.history.push(`/Solutions?id=${e.nav_id}`)
        break;
      case 'Products':
        this.props.history.push(`/Product?menu_id=${e.nav_id}`)
        break;
      default:
        this.props.history.push(e.nav_link_name)
    }
  }
  render() {
    const { footerNav, bottomData, follows, openKeys } = this.state
    const { language } = this.props
    return (
      <>
        {this.props.location.pathname !== '/404' && (
          <div className="footer-wrap">
            <div className='footer-menu'>
              <Menu
                openKeys={openKeys}
                mode="inline"
                className="menu-list"
                theme="dark"
                onOpenChange={this.onOpenChange.bind(this)}
              >
                {this.renderMenu(footerNav, true)}
              </Menu>
            </div>
            <div className="footer-content container">
              <ul className="footer-nav">
                {footerNav?.length &&
                  footerNav.map((item, index) => {
                    return (
                      <li key={index} style={{ width: `calc((100% / ${footerNav.length}) - 20px)` }}>
                        <h3 className="nav-name ellipsis">{item.nav_name}</h3>
                        {['Solutions', 'Products'].includes(item.nav_name) && item.children?.length > 0 && item.children.map((itemChild, childIndex) => {
                          return (
                            <p key={childIndex} className="nav-item ellipsis" onClick={this.jumpLink.bind(this, item.nav_name, itemChild)}>{itemChild.nav_name}</p>
                          )
                        })}
                        {!['Solutions', 'Products'].includes(item.nav_name) && item.children?.length > 0 &&
                          item.children.map((itemChild, childIndex) => {
                            return (
                              <Link to={itemChild.nav_link_name
                                || ''} key={childIndex}>
                                <p className="nav-item ellipsis">{itemChild.nav_name}</p>
                              </Link>
                            )
                          })}
                      </li>
                    )
                  })}
              </ul>
              <div className="content-right">
                <h3 className="news-title"> {language.lang_subscribe_to_news}</h3>
                <Form onFinish={this.onFinish.bind(this)} className="email-box" ref={this.formRef}>
                  <Form.Item
                    rules={[
                      { required: true, message: `${language.lang_please_enter_email}` },
                      () => ({
                        validator(rules, value) {
                          const mailReg = /^[\w]+([._-][\w]+)*@([-_\w]+\.)+[a-z]{2,14}$/i
                          if (!value) {
                            return Promise.reject()
                          }
                          if (mailReg.test(value)) {
                            return Promise.resolve()
                          } else {
                            return Promise.reject(language.lang_please_enter_correct_email)
                          }
                        },
                      }),
                    ]}
                    validateTrigger="onChange"
                    name="email"
                  >
                    <Input placeholder={language.lang_email} />
                  </Form.Item>
                  <Button className="icon-btn" htmlType="submit">
                    <i className="iconfont icon-fasongjiantou"></i>
                  </Button>
                </Form>
                <ul className="media-list">
                  {follows.length > 0 && follows.map((item, index) => {
                    return (<li key={index}>
                      <span herf={item.follow_linkurl} onClick={this.handleLink.bind(this, item)} className="media-link">
                        <i className={`iconfont ${item.iconfont}`}></i>
                      </span>
                    </li>)
                  })}
                </ul>
              </div>
            </div>
            <div className="footer-bottom">
              <div className="bottom-content-pc container">
                <div className="bottom-left-content">
                  <span>{bottomData.beian_one}</span>
                  <a className="beian1" href={bottomData.beian_two_url}>{bottomData.beian_two}</a>
                  <div>
                    {/* <img src={safety}></img> */}
                    <a className="beian2" href={bottomData.beian_three_url}>{bottomData.beian_three}</a>
                  </div>
                </div>
                <span className='privacy-link' onClick={this.handlePrivacy.bind(this)} >
                  {language.lang_privacy_statement}
                </span>
              </div>
              <div className='bottom-content'>
                <div className='container' onClick={this.handlePrivacy.bind(this)} >
                  {language.lang_privacy_statement}
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    )
  }
}

const stateToProps = (state) => {
  return {
    language: state.language,
  }
}
export default connect(stateToProps, null)(withRouter(Footer)) 
